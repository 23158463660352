export const DIMENSION = {
    Desktop: 'desktop',
    Tablet: 'tablet',
    Mobile: 'mobile',
};

export const LAYOUT = {
    Fluid: 'fluid',
    Boxed: 'boxed',
};

export const RADIUS = {
    Rounded: 'rounded',
    Standard: 'standard',
    Flat: 'flat',
};

export const THEME_COLOR = {
    CentroCorportivo: 'centro-corportivo',
    LightBlue: 'light-blue',
    DarkBlue: 'dark-blue',
    LightRed: 'light-red',
    DarkRed: 'dark-red',
    LightGreen: 'light-green',
    DarkGreen: 'dark-green',
    LightPurple: 'light-purple',
    DarkPurple: 'dark-purple',
    LightPink: 'light-pink',
    DarkPink: 'dark-pink',
    Refrigerantes: 'refrigerantes',
    Veiculos: 'veiculos',
};

export const NAV_COLOR = {
    Default: 'default',
    Light: 'light',
    Dark: 'dark',
};
export const MENU_PLACEMENT = {
    Vertical: 'vertical',
    Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
    Pinned: 'pinned',
    Unpinned: 'unpinned',
};

export const USER_ROLE = {
    Admin: 'admin',
    Editor: 'editor',
};
