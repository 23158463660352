import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

const home = {
    home: lazy(() => import('views/home/Home')),
    perfil: lazy(() => import('views/perfil/Index')),
    intranets: lazy(() => import('views/processos/intranets/Intranets')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;
const mainMenu = [
    {
        path: DEFAULT_PATHS.APP,
        exact: true,
        redirect: true,
        to: `${appRoot}/home`,
    },
    {
        path: `/home`,
        label: 'Início',
        icon: 'home',
        component: home.home,
    },
    {
        path: `http://mail.brasal.com.br`,
        label: 'E-mail',
        icon: 'email',
        isExternal: true
    },
    {
        path: `${appRoot}/colaboracao/fala-brasal`,
        label: 'Fala Brasal',
        icon: 'messages',
        component: home.home,
    },
    {
        path: `${appRoot}/comunicacao/pagina/5432/slug/denuncias`,
        label: 'Canal de Ética',
        icon: 'notification',
        component: home.home,
    },
    {
        path: `${appRoot}/processos/intranets`,
        label: 'Intranets',
        icon: 'grid-1',
        component: home.intranets,
    },
    {
        path: `${appRoot}/perfil-colaborador`,
        //label: 'Perfil do Colaborador',
        component: home.perfil,
    }
];
export default mainMenu;
